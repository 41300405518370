export const permissions = [
  {
    id: 1,
    code: 'tiles-charts',
    name: 'Tiles & Charts',
  },
  {
    id: 2,
    code: 'organization-create',
    name: 'Organization-Create',
  },
  {
    id: 3,
    code: 'organization-edit',
    name: 'Organization-Edit',
  },
  {
    id: 4,
    code: 'organization-delete',
    name: 'Organization-Delete',
  },
  {
    id: 5,
    code: 'organization-view',
    name: 'Organization-View',
  },
  {
    id: 6,
    code: 'organization-list',
    name: 'Organization-List',
  },
  {
    id: 7,
    code: 'facility-create',
    name: 'Facility-Create',
  },
  {
    id: 8,
    code: 'facility-edit',
    name: 'Facility-Edit',
  },
  {
    id: 9,
    code: 'facility-delete',
    name: 'Facility-Delete',
  },
  {
    id: 10,
    code: 'facility-view',
    name: 'Facility-View',
  },
  {
    id: 11,
    code: 'facility-list',
    name: 'Facility-List',
  },
  {
    id: 39,
    code: 'facility-category-list',
    name: 'FacilityCategory-List',
  },
  {
    id: 40,
    code: 'facility-wordbank-list',
    name: 'FacilityWordBank-List',
  },
  {
    id: 41,
    code: 'facility-questionbank-list',
    name: 'FacilityQuestionBank-List',
  },
  {
    id: 24,
    code: 'category-create',
    name: 'Category-Create',
  },
  {
    id: 25,
    code: 'category-edit',
    name: 'Category-Edit',
  },
  {
    id: 26,
    code: 'category-delete',
    name: 'Category-Delete',
  },
  {
    id: 28,
    code: 'category-list',
    name: 'Category-List',
  },
  {
    id: 29,
    code: 'keyword-create',
    name: 'Keyword-Create',
  },
  {
    id: 30,
    code: 'keyword-edit',
    name: 'Keyword-Edit',
  },
  {
    id: 31,
    code: 'keyword-delete',
    name: 'Keyword-Delete',
  },

  {
    id: 33,
    code: 'keyword-list',
    name: 'Keyword-List',
  },
  {
    id: 34,
    code: 'question-create',
    name: 'Question-Create',
  },
  {
    id: 35,
    code: 'question-edit',
    name: 'Question-Edit',
  },
  {
    id: 36,
    code: 'question-delete',
    name: 'Question-Delete',
  },
  {
    id: 38,
    code: 'question-list',
    name: 'Question-List',
  },

  {
    id: 18,
    code: 'user-create',
    name: 'User-Create',
  },
  {
    id: 19,
    code: 'user-edit',
    name: 'User-Edit',
  },
  {
    id: 20,
    code: 'user-delete',
    name: 'User-Delete',
  },
  {
    id: 21,
    code: 'user-view',
    name: 'User-View',
  },
  {
    id: 22,
    code: 'user-list',
    name: 'User-List',
  },

  {
    id: 12,
    code: 'referral-create',
    name: 'Referral-Create',
  },
  {
    id: 13,
    code: 'referral-edit',
    name: 'Referral-Edit',
  },
  {
    id: 14,
    code: 'referral-delete',
    name: 'Referral-Delete',
  },
  {
    id: 15,
    code: 'referral-view',
    name: 'Referral-View',
  },
  {
    id: 17,
    code: 'referral-list',
    name: 'Referral List',
  },
  {
    id: 42,
    code: 'referral-hold',
    name: 'Referral Hold',
  },
  {
    id: 43,
    code: 'referral-hold-list',
    name: 'Referral Hold List',
  },
  {
    id: 44,
    code: 'referral-lost',
    name: 'Referral Lost',
  },
  {
    id: 45,
    code: 'referral-lost-list',
    name: 'Referral Lost List',
  },
  {
    id: 46,
    code: 'referral-draft',
    name: 'Referral Draft',
  },
  {
    id: 47,
    code: 'referral-processing',
    name: 'Referral Processing',
  },

  {
    id: 48,
    code: 'referral-pending-clerical-review',
    name: 'Pending Clerical Review',
  },
  {
    id: 49,
    code: 'referral-clerical-review',
    name: 'Clerical Review',
  },
  {
    id: 50,
    code: 'referral-clerical-submitted',
    name: 'Clerical Submitted',
  },
  {
    id: 51,
    code: 'referral-clerical-denial-pending',
    name: 'Clerical Denial Pending',
  },
  {
    id: 52,
    code: 'referral-clinical-review',
    name: 'Clinical Review',
  },
  {
    id: 53,
    code: 'referral-clinical-submitted',
    name: 'Clinical Submitted',
  },
  {
    id: 54,
    code: 'referral-clinical-denial-pending',
    name: 'Clinical Denial Pending',
  },
  {
    id: 55,
    code: 'referral-community-review',
    name: 'Community Review',
  },
  {
    id: 56,
    code: 'referral-community-accepted',
    name: 'Community Accepted',
  },
  {
    id: 57,
    code: 'referral-community-denial-review',
    name: 'Community Denial Review',
  },
  {
    id: 58,
    code: 'referral-clerical-denial-approved',
    name: 'Clerical Denial Approved',
  },
  {
    id: 59,
    code: 'referral-clerical-denial-overturned',
    name: 'Clerical Denial Overturned',
  },
  {
    id: 60,
    code: 'referral-clinical-denial-approved',
    name: 'Clinical Denial Approved',
  },
  {
    id: 61,
    code: 'referral-clinical-denial-overturned',
    name: 'Clinical Denial Overturned',
  },
  {
    id: 62,
    code: 'referral-community-denial-approved',
    name: 'Community Denial Approved',
  },
  {
    id: 63,
    code: 'referral-community-denial-overturned',
    name: 'Community Denial Overturned',
  },
  {
    id: 64,
    code: 'referral-pending-patient-admission',
    name: 'Pending Patient Admission',
  },
  {
    id: 65,
    code: 'referral-pending-patient-request',
    name: 'Pending Patient Request',
  },
  {
    id: 66,
    code: 'referral-pending-patient-admitted',
    name: 'Pending Patient Admitted',
  },
  {
    id: 67,
    code: 'referral-pending-patient-rejected',
    name: 'Pending Patient Rejected',
  },
  {
    id: 68,
    code: 'drug-price',
    name: 'Drug Price',
  },
  {
    id: 69,
    code: 'notification-pending-clerical-review',
    name: 'Pending Clerical Review',
  },
  {
    id: 70,
    code: 'notification-clerical-submitted',
    name: 'Clerical Submitted',
  },
  {
    id: 71,
    code: 'notification-clerical-denial-pending',
    name: 'Clerical Denial Pending',
  },
  {
    id: 72,
    code: 'notification-clerical-denial-approved',
    name: 'Clerical Denial Approved',
  },
  {
    id: 73,
    code: 'notification-clerical-denial-overturned',
    name: 'Clerical Denial Overturned',
  },
  {
    id: 74,
    code: 'notification-clinical-submitted',
    name: 'Clinical Submitted',
  },
  {
    id: 75,
    code: 'notification-clinical-denial-pending',
    name: 'Clinical Denial Pending',
  },
  {
    id: 76,
    code: 'notification-community-accepted',
    name: 'Community Accepted',
  },

  {
    id: 77,
    code: 'notification-community-denial-review',
    name: 'Community Denial Review',
  },

  {
    id: 78,
    code: 'notification-lost',
    name: 'Lost',
  },
  {
    id: 79,
    code: 'taskList-accept',
    name: 'TaskListAccept',
  },
  {
    id: 80,
    code: 'taskList-deny',
    name: 'TaskListDeny',
  },
  {
    id: 83,
    code: 'referral-unlock',
    name: 'Referral Unlock',
  },
  {
    id: 86,
    code: 'regenerate-pdf',
    name: 'RegeneratePDF',
  },
  {
    id: 87,
    code: 'notif-pdf-upload-failure',
    name: 'PDF upload failure',
  },
  {
    id: 88,
    code: 'hra-list',
    name: 'HRA-List',
  },
  {
    id: 89,
    code: 'hra-create',
    name: 'HRA-Create',
  },
  {
    id: 90,
    code: 'hra-update',
    name: 'HRA-Update',
  },
  {
    id: 91,
    code: 'hra-delete',
    name: 'HRA-Delete',
  },
]
