/**
 * Environment variables
 */
export const Env = {
  NODE_ENV: process.env.REACT_APP_ENV,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  SENDBIRD_CHAT_ID: process.env.REACT_APP_SENDBIRD_CHAT_APP_ID,
  NOTIFICATION_CHANNEL_ID: process.env.REACT_APP_SENDBIRD_NOTIFICATIONS_CHANNEL_ID,
  CENTRAL_INTAKE_LINK: process.env.REACT_APP_CENTRAL_INTAKE_LINK,
  PAC_IQ_DOMO_LINK: process.env.REACT_APP_PAC_IQ_DOMO_LINK,
  PUBLIC_DOMO_LINK: process.env.REACT_APP_DOMO_PUBLIC_LINK,
  TASKLIST_CHANNEL_ID: process.env.REACT_APP_TASKLIST_NOTIFICATIONS_CHANNEL_ID,
  AUTO_LOGOUT_TIMEOUT_DURATION: process.env.REACT_APP_AUTO_LOGOUT_TIMEOUT_DURATION,

  isProd() {
    return this.NODE_ENV === 'production'
  },
  isDev() {
    return this.NODE_ENV === 'development'
  },
  isTest() {
    return this.NODE_ENV === 'test'
  },
  isStage() {
    return this.NODE_ENV === 'stage'
  },
  isLocal() {
    return this.NODE_ENV === 'local'
  },
}

export default Env
