/* eslint-disable @typescript-eslint/no-unused-vars */
import { Env } from 'config/Env'
import { Envelope } from 'utils/types/Envelope'

import { HttpClient, ApiRequestTypes } from '../../../utils/apiService/httpService'
import {
  BroadcastMessageObject,
  BroadcastMessageResponse,
  CardResponse,
  CreateBulkDiscussionsRequestData,
  CreateBulkDiscussionsResponse,
  CreateThreadData,
  PatiendDiscussionsStatusResponse,
  ThreadCreateResponse,
} from '../../referrals/types'
import { DiscussionHeaderDataType } from '../types'

const REFERRAL_LIST = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetReferralsForChat`
const CREATE_THREAD = `${Env.REACT_APP_API_URL}Referral/api/ReferralDiscussion/CreateReferralDiscussion`
const GET_REFERRAL_SOURCE_PDF_URL = `${Env.REACT_APP_API_URL}Referral/api/ReferralChatAttachment/GetAttachment`
const SEND_REFERRAL_CHAT_ATTACHMENT = `${Env.REACT_APP_API_URL}Referral/api/ReferralChatAttachment/UploadAttachment`
const DELETE_CHAT_ATTACHMENT_URL = `${Env.REACT_APP_API_URL}Referral/api/ReferralChatAttachment/DeleteAttachment`
const GET_CHAT_ACCESS_TOKEN = `${Env.REACT_APP_API_URL}Identity/api/DIscussionUser/GenerateSessionToken`
const GET_REFERRAL_HEADER = `${Env.REACT_APP_API_URL}Referral/api/Referral/GetReferralHeader`
const GET_PATIENT_DISCUSSIONS_STATUS = `${Env.REACT_APP_API_URL}Referral/api/ReferralDiscussion/GetPatientDiscussionsStatus`
const CREATE_BULK_DISCUSSIONS = `${Env.REACT_APP_API_URL}Referral/api/ReferralDiscussion/CreateBulkDiscussions`
const SEND_BROADCAST_MESSAGE = `${Env.REACT_APP_API_URL}Referral/api/ReferralDiscussion/SendBroadcastMessage`

export const getRefLists = (requestBody: unknown): Promise<CardResponse> =>
  HttpClient[ApiRequestTypes.post](REFERRAL_LIST, requestBody)

export const createThread = (requestBody: CreateThreadData): Promise<ThreadCreateResponse> =>
  HttpClient[ApiRequestTypes.post](CREATE_THREAD, requestBody)

export const getReferralSourcePdf = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${GET_REFERRAL_SOURCE_PDF_URL}?fileId=${id}`)

export const sendReferralChatAttachment = (requestBody: unknown): Promise<CardResponse> =>
  HttpClient[ApiRequestTypes.post](SEND_REFERRAL_CHAT_ATTACHMENT, requestBody)

export const deleteChatAttachment = (id: number): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](`${DELETE_CHAT_ATTACHMENT_URL}?id=${id}`)

export const getChatSessionToken = (): Promise<Envelope> =>
  HttpClient[ApiRequestTypes.get](GET_CHAT_ACCESS_TOKEN)

export const getReferralHeader = (
  patientFacilityId: number,
): Promise<{ data: DiscussionHeaderDataType }> =>
  HttpClient[ApiRequestTypes.get](`${GET_REFERRAL_HEADER}?patientFacilityId=${patientFacilityId}`)

export const getPatientDiscussionStatus = (
  patientId: number,
): Promise<PatiendDiscussionsStatusResponse> =>
  HttpClient[ApiRequestTypes.get](`${GET_PATIENT_DISCUSSIONS_STATUS}?patientId=${patientId}`)

export const createBulkDiscussions = (
  requestBody: CreateBulkDiscussionsRequestData,
): Promise<CreateBulkDiscussionsResponse> =>
  HttpClient[ApiRequestTypes.post](CREATE_BULK_DISCUSSIONS, requestBody)

export const sendBroadcastMessage = (
  requestBody: BroadcastMessageObject,
): Promise<BroadcastMessageResponse> =>
  HttpClient[ApiRequestTypes.post](SEND_BROADCAST_MESSAGE, requestBody)
